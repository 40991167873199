<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="layout-top-spacing">
        <div class="layout-spacing">
          <div class="mb-3 d-flex justify-content-between align-items-start">
            <div>
              <h3>Vendors</h3>
              <a href="javascript:void(0);" @click="openExportModal()">
                Export Transactions
              </a>
            </div>
            <b-btn variant="primary" v-b-modal.vendor-form>
              <i class="fa fa-plus mr-1"></i>
              Create Vendor
            </b-btn>
          </div>

          <b-row class="mb-3">
            <b-col
              class="mb-2"
              v-if="
                paystackBalance &&
                (isManager() || isAdmin() || isMedicalServices())
              "
            >
              <div class="p-3 bg-white shadow rounded">
                <p>Paystack Balance</p>
                <h3>{{ paystackBalance }}</h3>
              </div>
            </b-col>
            <b-col class="mb-2">
              <div class="p-3 bg-white shadow rounded">
                <p>Month Contract Sum Total</p>
                <h3>
                  {{
                    monthcontractBalance ? formatMoney(monthcontractBalance) : 0
                  }}
                </h3>
              </div>
            </b-col>
            <b-col class="mb-2">
              <div class="p-3 bg-white shadow rounded">
                <p>Oneoff Sum Total</p>
                <h3>{{ oneoffBalance ? formatMoney(oneoffBalance) : 0 }}</h3>
              </div>
            </b-col>
          </b-row>

          <div class="widget-content widget-content-area">
            <div class="table-responsive">
              <table class="table table-bordered w-100" id="vendors-table">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Type</th>
                    <th>Wallet Balance</th>
                    <th>Created By</th>
                    <th>Created Date</th>
                    <th>Last Modified Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Create/Update modal -->
      <b-modal
        id="vendor-form"
        :title="(editing ? 'Update' : 'Create') + ' a Vendor'"
        centered
        @hide="vendorForm.reset()"
      >
        <form
          method="POST"
          @submit.prevent="editing ? updateVendor() : createVendor()"
          @keydown="vendorForm.onKeydown($event)"
        >
          <AlertError :form="vendorForm" />

          <b-form-group
            description=""
            label="Name *"
            label-for="name"
            :invalid-feedback="vendorForm.errors.get('name')"
            :state="!vendorForm.errors.has('name')"
          >
            <b-form-input
              id="name"
              v-model="vendorForm.name"
              placeholder="Enter the name of the vendor"
              type="text"
              :state="vendorForm.errors.has('name') ? false : null"
              trim
            ></b-form-input>
          </b-form-group>

          <b-form-group
            description="E-mail address to enable us send notifications to"
            label="Email *"
            label-for="email"
            :invalid-feedback="vendorForm.errors.get('email')"
            :state="!vendorForm.errors.has('email')"
          >
            <b-form-input
              id="email"
              v-model="vendorForm.email"
              placeholder="Enter the email of the vendor"
              type="email"
              :state="vendorForm.errors.has('email') ? false : null"
              trim
            ></b-form-input>
          </b-form-group>

          <b-form-group
            description="Telephone number"
            label="Phone number"
            label-for="phone_number"
            :invalid-feedback="vendorForm.errors.get('phone_number')"
            :state="!vendorForm.errors.has('phone_number')"
          >
            <b-form-input
              id="phone_number"
              v-model="vendorForm.phone_number"
              placeholder="Enter the phone number of the vendor"
              type="tel"
              :state="vendorForm.errors.has('phone_number') ? false : null"
              trim
            ></b-form-input>
          </b-form-group>

          <b-form-group
            description=""
            label="Contact Name"
            label-for="contact_name"
            :invalid-feedback="vendorForm.errors.get('contact_name')"
            :state="!vendorForm.errors.has('contact_name')"
          >
            <b-form-input
              id="contact_name"
              v-model="vendorForm.contact_name"
              placeholder="Enter the contact person name of the vendor"
              type="text"
              :state="vendorForm.errors.has('contact_name') ? false : null"
              trim
            ></b-form-input>
          </b-form-group>

          <b-form-group
            description="Telephone number to reach the vendor"
            label="Contact Phone number"
            label-for="contact_phone_number"
            :invalid-feedback="vendorForm.errors.get('contact_phone_number')"
            :state="!vendorForm.errors.has('contact_phone_number')"
          >
            <b-form-input
              id="contact_phone_number"
              v-model="vendorForm.contact_phone_number"
              placeholder="Enter the phone number of the contact person"
              type="tel"
              :state="
                vendorForm.errors.has('contact_phone_number') ? false : null
              "
              trim
            ></b-form-input>
          </b-form-group>

          <b-form-group
            description="How should vendor receive payments?"
            label="Offer Type"
            label-for="type"
            :invalid-feedback="vendorForm.errors.get('type')"
            :state="!vendorForm.errors.has('type')"
          >
            <b-form-select
              id="type"
              v-model="vendorForm.type"
              :options="vendor_types"
              :state="vendorForm.errors.has('type') ? false : null"
            >
              <template #first>
                <b-form-select-option value="" disabled>
                  -- Please select an option --
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </form>

        <template #modal-footer="{ cancel }">
          <b-button size="sm" variant="danger" @click="cancel()">
            Cancel
          </b-button>
          <b-button
            size="sm"
            variant="success"
            @click="editing ? updateVendor() : createVendor()"
            :disabled="vendorForm.busy"
          >
            <b-spinner label="Loading" v-if="vendorForm.busy" small></b-spinner>
            {{ editing ? "Update" : "Create" }}
          </b-button>
        </template>
      </b-modal>

      <!-- Dummy: Edit modal - To mock the vendor form modal -->
      <div id="edit-vendor-modal"></div>

      <!-- Dummy: View modal - To mock the vendor view modal -->
      <div id="mock-view-vendor-modal"></div>

      <!-- Dummy: Wallet update modal - To mock the wallet update modal -->
      <div id="mock-wallet-update-vendor-modal"></div>

      <!-- Real: View modal-->
      <b-modal id="view-vendor-modal" size="xl" centered scrollable>
        <template #modal-title>
          Vendor: <code>{{ vendor ? vendor.name : "" }}</code>
        </template>

        <div class="row">
          <div class="col-md-6">
            <b-table
              :fields="[
                'name',
                'email',
                'phone_number',
                'contact_name',
                'contact_phone_number',
                'type',
                'wallet_balance',
                'payment_withheld_at',
                'suspended_at',
              ]"
              :items="[vendor]"
              stacked
              responsive
              borderless
            >
              <template #cell(type)="data">
                <span :class="'badge badge-' + getVendorTypeColor(data.value)">
                  {{ data.value.replace("_", " ") }}
                </span>
              </template>

              <template #cell(wallet_balance)="data">
                {{ formatMoney(data.value) }}
              </template>

              <template #cell(payment_withheld_at)="data">
                {{ data.value ? formatFancyDateFull(data.value) : "" }}
              </template>

              <template #cell(suspended_at)="data">
                {{ data.value ? formatFancyDateFull(data.value) : "" }}
              </template>
            </b-table>
          </div>
          <div class="col-md-6">
            <b-card title="Update Vendor Bank Account" border-variant="light">
              <div class="mb-3">
                Account Name:
                <span class="font-weight-bold text-primary">{{
                  vendor
                    ? vendor.bank_account
                      ? vendor.bank_account.account_name
                      : ""
                    : ""
                }}</span>
              </div>

              <form
                method="POST"
                @submit.prevent="updateBankAccount()"
                @keydown="bankAccountForm.onKeydown($event)"
              >
                <AlertError :form="bankAccountForm" />

                <div class="form-group">
                  <label>Bank *</label>
                  <v-select
                    v-model="bankAccountForm.bank_id"
                    :options="banks"
                    label="name"
                    :filterable="false"
                    @search="vselectSearch"
                    placeholder="Select a bank"
                    :reduce="(bank) => bank.id"
                  >
                    <template #no-options> type to search banks... </template>

                    <template #list-header v-if="vselectSearchResults">
                      <div
                        class="bg-dark border-bottom p-3 d-flex justify-content-between"
                      >
                        <span class="small">
                          Total: {{ vselectSearchResults.total }}<br />
                          Pages: {{ vselectSearchResults.last_page }}<br />
                          Current Page: {{ vselectSearchResults.current_page }}
                        </span>
                        <div class="mb-2">
                          <b-btn
                            :disabled="!vselectSearchResults.prev_page_url"
                            @click="
                              vselectGoToPage(
                                vselectSearchResults.current_page - 1
                              )
                            "
                            variant="info"
                            class="mr-2"
                            size="sm"
                            squared
                          >
                            &laquo; Prev
                          </b-btn>
                          <b-btn
                            :disabled="!vselectSearchResults.next_page_url"
                            @click="
                              vselectGoToPage(
                                vselectSearchResults.current_page + 1
                              )
                            "
                            variant="success"
                            size="sm"
                            squared
                          >
                            Next &raquo;
                          </b-btn>
                        </div>
                      </div>
                    </template>
                  </v-select>
                </div>

                <b-form-group
                  label="Account Number *"
                  label-for="account_number"
                  :invalid-feedback="
                    bankAccountForm.errors.get('account_number')
                  "
                  :state="!bankAccountForm.errors.has('account_number')"
                >
                  <b-form-input
                    id="amount"
                    v-model="bankAccountForm.account_number"
                    placeholder="Enter your bank account number"
                    type="number"
                    :state="
                      bankAccountForm.errors.has('account_number')
                        ? false
                        : null
                    "
                    trim
                  ></b-form-input>
                </b-form-group>

                <b-button
                  size="sm"
                  variant="success"
                  @click="updateBankAccount()"
                  :disabled="bankAccountForm.busy"
                >
                  <b-spinner
                    label="Loading"
                    v-if="bankAccountForm.busy"
                    small
                  ></b-spinner>
                  Update Bank Account
                </b-button>
              </form>
            </b-card>
          </div>
        </div>

        <div class="table-responsive">
          <table
            class="table table-bordered w-100"
            id="vendor-wallet-transactions-table"
          >
            <thead>
              <tr>
                <th>S/N</th>
                <th>Ref.</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Note</th>
                <th>Payment Ref.</th>
                <th>Created Date</th>
              </tr>
            </thead>
          </table>
        </div>

        <template #modal-footer="{ cancel }">
          <b-button size="sm" variant="danger" @click="cancel()">
            Close
          </b-button>
        </template>
      </b-modal>

      <!-- Real: Wallet Update modal -->
      <b-modal id="wallet-update-vendor" size="lg" centered>
        <template #modal-title>
          Vendor: <code>{{ vendor ? vendor.name : "" }}</code>
        </template>

        <form
          method="post"
          @submit.prevent="updateVendorWallet()"
          @keydown="walletUpdateForm.onKeydown($event)"
        >
          <AlertError :form="walletUpdateForm" />

          <b-form-group label="Transaction Type" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="wallet-update-type"
              v-model="walletUpdateForm.type"
              :options="[
                { text: 'Credit', value: 'credit' },
                { text: 'Debit', value: 'debit' },
              ]"
              :aria-describedby="ariaDescribedby"
              name="wallet-update-type"
              required
            ></b-form-radio-group>
          </b-form-group>

          <b-form-group
            label="Amount *"
            label-for="amount"
            :invalid-feedback="walletUpdateForm.errors.get('amount')"
            :state="!walletUpdateForm.errors.has('amount')"
          >
            <b-form-input
              id="amount"
              v-model="walletUpdateForm.amount"
              type="number"
              :state="walletUpdateForm.errors.has('amount') ? false : null"
              min="0"
              max="5000000"
              trim
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            description="Why is this transaction being made?"
            label="Reason *"
            label-for="reason"
            :invalid-feedback="walletUpdateForm.errors.get('reason')"
            :state="!walletUpdateForm.errors.has('reason')"
          >
            <b-form-input
              id="reason"
              v-model="walletUpdateForm.reason"
              type="text"
              :state="walletUpdateForm.errors.has('reason') ? false : null"
              trim
              required
            ></b-form-input>
          </b-form-group>
        </form>

        <template #modal-footer="{ cancel }">
          <b-button size="sm" variant="dark" @click="cancel()">
            Close
          </b-button>
          <b-button
            size="sm"
            variant="primary"
            :disabled="walletUpdateForm.busy"
            @click="updateVendorWallet()"
          >
            <b-spinner
              label="Loading"
              v-if="walletUpdateForm.busy"
              small
            ></b-spinner>
            Save Transaction
          </b-button>
        </template>
      </b-modal>

      <!-- Suspension modal -->
      <div
        class="modal fade"
        id="suspension-modal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="suspension-modal-title">
                Are you sure you want to
                {{
                  vendor
                    ? vendor.suspended_at != "null"
                      ? "activate"
                      : "suspend"
                    : ""
                }}
                this vendor <code>{{ vendor ? vendor.email : "" }}</code>
                ?
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm btn-dark"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                :class="
                  'btn btn-sm ' +
                  (vendor
                    ? vendor.suspended_at != 'null'
                      ? 'btn-success'
                      : 'btn-danger'
                    : '')
                "
                @click="suspendVendor()"
                :disabled="processing"
              >
                {{
                  vendor
                    ? vendor.suspended_at != "null"
                      ? "Activate Vendor"
                      : "Suspend Vendor"
                    : ""
                }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Payment withholding modal -->
      <div
        class="modal fade"
        id="payment-withholding-modal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="payment-withholding-modal-title">
                Are you sure you want to
                {{
                  vendor
                    ? vendor.payment_withheld_at != "null"
                      ? "release"
                      : "hold"
                    : ""
                }}
                payment for this vendor
                <code>{{ vendor ? vendor.email : "" }}</code>
                ?
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm btn-dark"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                :class="
                  'btn btn-sm ' +
                  (vendor
                    ? vendor.payment_withheld_at != 'null'
                      ? 'btn-success'
                      : 'btn-danger'
                    : '')
                "
                @click="withholdVendorPayment()"
                :disabled="processing"
              >
                {{
                  vendor
                    ? vendor.payment_withheld_at != "null"
                      ? "Release"
                      : "Hold"
                    : ""
                }}
                Payment
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Trigger vendor payment modal -->
      <div
        class="modal fade"
        id="trigger-payment-modal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="trigger-payment-modal-title">
                Are you sure you want to force transfer vendor (<code>
                  {{ vendor ? vendor.email : "" }}</code
                >)'s payment?
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm btn-dark"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-sm btn-success"
                @click="triggerPayment()"
                :disabled="processing"
              >
                Yes, pay vendor
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Export modal -->
      <b-modal
        id="export-modal"
        title="Export Vendor Payment Transactions"
        centered
      >
        <form
          method="post"
          @submit.prevent="exportTransactions()"
          @keydown="exportTransactionsForm.onKeydown($event)"
        >
          <AlertError :form="exportTransactionsForm" />

          <b-form-group label="Vendors" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="vendors-export"
              v-model="exportTransactionsForm.vendors"
              :options="[
                { text: 'All', value: 'all' },
                {
                  text: 'Custom',
                  value:
                    exportTransactionsForm.vendors == 'all'
                      ? []
                      : exportTransactionsForm.vendors,
                },
              ]"
              :aria-describedby="ariaDescribedby"
              name="vendors-export"
            ></b-form-radio-group>

            <div class="mt-2" v-if="exportTransactionsForm.vendors != 'all'">
              <v-select
                v-model="exportTransactionsForm.vendors"
                :options="vendors"
                label="name"
                :reduce="(vendor) => vendor.id"
                multiple
              />
            </div>
          </b-form-group>

          <b-form-group
            label="Type"
            label-for="type"
            :invalid-feedback="exportTransactionsForm.errors.get('type')"
            :state="!exportTransactionsForm.errors.has('type')"
            v-if="exportTransactionsForm.vendors == 'all'"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="type-export"
              v-model="exportTransactionsForm.type"
              :options="vendor_types"
              :aria-describedby="ariaDescribedby"
              name="type-export"
            >
              <template #first>
                <b-form-radio value="">All</b-form-radio>
              </template>
            </b-form-radio-group>
          </b-form-group>

          <b-form-group
            label="Payment From Date"
            label-for="payment_from_date"
            :invalid-feedback="
              exportTransactionsForm.errors.get('payment_from_date')
            "
            :state="!exportTransactionsForm.errors.has('payment_from_date')"
          >
            <b-form-input
              id="payment_from_date"
              type="date"
              v-model="exportTransactionsForm.payment_from_date"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Payment To Date"
            label-for="payment_to_date"
            :invalid-feedback="
              exportTransactionsForm.errors.get('payment_to_date')
            "
            :state="!exportTransactionsForm.errors.has('payment_to_date')"
          >
            <b-form-input
              id="payment_to_date"
              type="date"
              v-model="exportTransactionsForm.payment_to_date"
            ></b-form-input>
          </b-form-group>
        </form>

        <template #modal-footer="{ cancel }">
          <b-button size="sm" variant="dark" @click="cancel()">
            Close
          </b-button>
          <b-button
            size="sm"
            variant="primary"
            :disabled="exportTransactionsForm.busy"
            @click="exportTransactions()"
          >
            <b-spinner
              label="Loading"
              v-if="exportTransactionsForm.busy"
              small
            ></b-spinner>
            Export
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { url, http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";
import { getToken } from "@/utils/jwt.service";
import $ from "jquery";
import vselectPaginationSearch from "@/mixins/v-select/pagination-search";
import roles from "@/mixins/roles";
import pagination from "@/mixins/pagination";

export default {
  name: "AllVendors",
  mixins: [vselectPaginationSearch, roles, pagination],
  data() {
    return {
      records: null,
      vendorForm: this.$Form({
        name: "",
        email: "",
        phone_number: "",
        type: "",
        contact_name: "",
        contact_phone_number: "",
      }),
      vendor_types: [
        { value: "month_contract", text: "monthly contract" },
        { value: "oneoff", text: "one-off" },
      ],
      vendor: null,
      editing: false,
      bankAccountForm: new this.$Form({
        bank_id: "",
        account_number: "",
      }),
      banks: [],
      vendorWalletTransactions: null,
      processing: false,
      paystackBalance: null,
      monthcontractBalance: null,
      oneoffBalance: null,
      vendors: [],
      exportTransactionsForm: new this.$Form({
        vendors: "all",
        type: "",
        payment_from_date: "",
        payment_to_date: "",
      }),
      walletUpdateForm: new this.$Form({
        type: null,
        amount: null,
        reason: null,
      }),
    };
  },
  methods: {
    fetchRecords() {
      this.records = $("#vendors-table").DataTable({
        dom: "<'d-md-flex justify-content-between'<'d-md-flex'<l><'ml-2'B>><f>>tipr",
        buttons: ["colvis"],
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50, -1],
          [10, 25, 50, "All"],
        ],
        processing: true,
        serverSide: true,
        ajax: {
          url: `${url}${endpoints.VIEW_ALL_VENDORS}?datatable=1&include=creator`,
          type: "GET",
          beforeSend: function (request) {
            request.setRequestHeader("Accept", "application/json");
            request.setRequestHeader("Authorization", `Bearer ${getToken()}`);
          },
          error: (xhr) => {
            // unauthenticated
            if (xhr.status == 401) {
              this.$router.replace({ path: "login" });
            }

            this.$toast.error(xhr.statusText);
          },
        },
        columns: [
          {
            data: "DT_RowIndex",
            orderable: false,
            searchable: false,
          },
          {
            data: "name",
          },
          {
            data: "email",
          },
          {
            data: "phone_number",
          },
          {
            data: "type",
            render: function (data) {
              let color = "info";

              switch (data) {
                case "oneoff":
                  color = "dark";
                  break;
              }

              return `<span class="badge badge-${color} p-1">${data.replace(
                "_",
                " "
              )}</span>`;
            },
          },
          {
            data: "wallet_balance",
            searchable: false,
            render: (data) => {
              return this.formatMoney(data);
            },
          },
          {
            data: "creator",
            orderable: false,
            searchable: false,
            render: function (data) {
              return `${data?.firstname} ${data?.lastname}`;
            },
          },
          {
            data: "created_at",
            searchable: false,
            render: (data) => {
              return this.formatDateTime(data);
            },
          },
          {
            data: "updated_at",
            searchable: false,
            render: (data) => {
              return this.formatDateTime(data);
            },
          },
          {
            data: null,
            orderable: false,
            searchable: false,
            render: (data) => {
              let actions = `
                <button
                  class="btn btn-sm btn-primary mr-1"
                  title="View"
                  data-toggle="modal"
                  data-target="#mock-view-vendor-modal"
                  data-vendor-id="${data.id}"
                >
                  <i class="fa fa-eye"></i>
                </button>
                <button
                  class="btn btn-sm btn-info mr-1"
                  title="Edit"
                  data-toggle="modal"
                  data-target="#edit-vendor-modal"
                  data-vendor-id="${data.id}"
                  data-vendor-name="${data.name}"
                  data-vendor-email="${data.email}"
                  data-vendor-phone_number="${data.phone_number}"
                  data-vendor-type="${data.type}"
                  data-vendor-contact_name="${data.contact_name}"
                  data-vendor-contact_phone_number="${data.contact_phone_number}"
                >
                  <i class="fa fa-edit"></i>
                </button>
              `;

              if (this.isManager() || this.isAdmin()) {
                actions += `
                  <button
                    class="btn btn-sm ${
                      data.suspended_at ? "btn-success" : "btn-danger"
                    } mr-1"
                    title="${data.suspended_at ? "Activate" : "Suspend"}"
                    data-toggle="modal"
                    data-target="#suspension-modal"
                    data-vendor-id="${data.id}"
                    data-vendor-email="${data.email}"
                    data-vendor-suspended_at="${data.suspended_at}"
                  >
                    <i class="fa fa-${
                      data.suspended_at ? "thumbs-up" : "thumbs-down"
                    }"></i>
                  </button>
                  <button
                    class="btn btn-sm ${
                      data.payment_withheld_at ? "btn-danger" : "btn-success"
                    } mr-1"
                    title="${
                      data.payment_withheld_at
                        ? "Release Payment"
                        : "Hold Payment"
                    }"
                    data-toggle="modal"
                    data-target="#payment-withholding-modal"
                    data-vendor-id="${data.id}"
                    data-vendor-email="${data.email}"
                    data-vendor-payment_withheld_at="${
                      data.payment_withheld_at
                    }"
                  >
                    <i class="fa fa-${
                      data.payment_withheld_at ? "lock" : "unlock"
                    }"></i>
                  </button>
                `;

                if (!data.payment_withheld_at && data.wallet_balance > 100) {
                  actions += `
                    <button
                      class="btn btn-sm btn-dark mr-1"
                      title="Trigger payment"
                      data-toggle="modal"
                      data-target="#trigger-payment-modal"
                      data-vendor-id="${data.id}"
                      data-vendor-email="${data.email}"
                    >
                      <i class="fa fa-fire"></i>
                    </button>
                  `;
                }
              }

              if (this.isManager() || this.isAdmin()) {
                actions += `
                  <button
                    class="btn btn-sm btn-primary mr-1"
                    title="Update Wallet"
                    data-toggle="modal"
                    data-target="#mock-wallet-update-vendor-modal"
                    data-vendor-id="${data.id}"
                    data-vendor-name="${data.name}"
                    data-vendor-email="${data.email}"
                    data-vendor-phone_number="${data.phone_number}"
                    data-vendor-type="${data.type}"
                    data-vendor-contact_name="${data.contact_name}"
                    data-vendor-contact_phone_number="${data.contact_phone_number}"
                  >
                    <i class="fa fa-credit-card"></i>
                  </button>
                `;
              }

              return `<div class="d-flex">${actions}</div>`;
            },
          },
        ],
      });
    },
    getVendorTypeColor(type) {
      let color = "info";

      switch (type) {
        case "oneoff":
          color = "dark";
          break;
      }

      return color;
    },
    refreshRecords() {
      this.records.ajax.reload();
    },
    fetchVendor(id, openViewModal = false) {
      this.bankAccountForm.reset();

      http
        .get(`${endpoints.VIEW_VENDOR.replace(":id", id)}?include=bankAccount`)
        .then((response) => {
          this.vendor = response;

          if (this.vendor.bank_account) {
            this.banks = [
              {
                id: this.vendor.bank_account.bank.id,
                name: this.vendor.bank_account.bank.name,
              },
            ];

            this.bankAccountForm.fill({
              bank_id: this.vendor.bank_account.bank_id,
              account_number: this.vendor.bank_account.account_number,
            });
          }

          if (openViewModal) {
            this.$bvModal.show("view-vendor-modal");
            setTimeout(() => {
              this.fetchVendorWalletTransactions();
            }, 500);
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },
    createVendor() {
      this.vendorForm
        .post(endpoints.CREATE_VENDOR)
        .then((response) => {
          this.$toast.success(response);
          this.$bvModal.hide("vendor-form");
          this.refreshRecords();
        })
        .catch((error) => {
          this.$toast.error(error.response?.message.message);
        });
    },
    editVendor() {
      this.editing = true;
      this.vendorForm.fill(this.vendor);
      this.$bvModal.show("vendor-form");
    },
    updateVendor() {
      this.vendorForm
        .patch(endpoints.UPDATE_VENDOR.replace(":id", this.vendor.id))
        .then((response) => {
          this.$toast.success(response);
          this.$bvModal.hide("vendor-form");
          this.refreshRecords();
        })
        .catch((error) => {
          this.$toast.error(error.response?.message.message);
        });
    },
    suspendVendor() {
      if (!this.vendor.id) {
        alert("Something is wrong. Contact the engineering team!");
        return;
      }

      this.processing = true;

      http
        .patch(endpoints.SUSPEND_VENDOR.replace(":id", this.vendor.id))
        .then((response) => {
          $("#suspension-modal").modal("hide");
          this.$toast.success(response);
          this.refreshRecords();
        })
        .catch((error) => {
          this.$toast.error(error.response?.message.message);
        })
        .finally(() => {
          this.processing = false;
        });
    },
    withholdVendorPayment() {
      if (!this.vendor.id) {
        alert("Something is wrong. Contact the engineering team!");
        return;
      }

      this.processing = true;

      http
        .patch(endpoints.HOLD_VENDOR_PAYMENT.replace(":id", this.vendor.id))
        .then((response) => {
          $("#payment-withholding-modal").modal("hide");
          this.$toast.success(response);
          this.refreshRecords();
        })
        .catch((error) => {
          this.$toast.error(error.response?.message);
        })
        .finally(() => {
          this.processing = false;
        });
    },
    triggerPayment() {
      if (!this.vendor.id) {
        alert("Something is wrong. Contact the engineering team!");
        return;
      }

      this.processing = true;

      http
        .post(endpoints.TRIGGER_VENDOR_PAYMENT.replace(":id", this.vendor.id))
        .then((response) => {
          $("#trigger-payment-modal").modal("hide");
          this.$toast.success(response);
          this.refreshRecords();
        })
        .catch((error) => {
          this.$toast.error(error.response?.message);
        })
        .finally(() => {
          this.processing = false;
        });
    },
    vselectComponentSearch(loading, vm) {
      http
        .get(endpoints.GET_ALL_BANKS, {
          params: {
            page: vm.vselectSearchPage,
            "filter[name]": vm.vselectSearchTerm,
          },
        })
        .then((response) => {
          vm.vselectSearchResults = response;
          vm.banks = response.data;
        })
        .catch((error) => {
          vm.$toast.error(error);
        })
        .finally(() => {
          loading(false);
        });
    },
    vselectComponentClearSelection() {
      this.bankAccountForm.reset();
    },
    updateBankAccount() {
      this.bankAccountForm
        .post(
          endpoints.UPDATE_VENDOR_BANK_ACCOUNT.replace(":id", this.vendor.id)
        )
        .then((response) => {
          this.$toast.success(response);
          this.fetchVendor(this.vendor.id);
        })
        .catch((error) => {
          this.$toast.error(error.response?.message.message);
        });
    },
    getPaystackBalance() {
      http
        .get(endpoints.GET_PAYSTACK_BALANCE)
        .then((response) => {
          this.paystackBalance = response.data
            .filter((data) => data.currency == "NGN")
            .map((data) => {
              data.balance = data.balance / 100;
              return this.formatMoney(data.balance, data.currency);
            })
            .join(" ⎯⎯⎯ ");
        })
        .catch((error) => {
          this.$toast.error(error?.response?.data?.message);
        });
    },
    getVendorBalances() {
      http
        .get(endpoints.GET_VENDOR_SUM_BALANCES)
        .then((response) => {
          if (response.length) {
            this.monthcontractBalance = response[0]?.sum_month_contract;
            this.oneoffBalance = response[0]?.sum_oneoff;
          }
        })
        .catch((error) => {
          this.$toast.error(
            error?.response?.data?.message || error?.response?.message
          );
        });
    },
    fetchVendorWalletTransactions() {
      this.vendorWalletTransactions = $(
        "#vendor-wallet-transactions-table"
      ).DataTable({
        pageLength: 5,
        lengthMenu: [
          [5, 10, 25, 50],
          [5, 10, 25, 50],
        ],
        processing: true,
        serverSide: true,
        ajax: {
          url: `${url}${endpoints.VIEW_ALL_VENDOR_WALLET_TRANSACTIONS.replace(
            ":id",
            this.vendor.id
          )}?datatable=1`,
          type: "GET",
          beforeSend: function (request) {
            request.setRequestHeader("Accept", "application/json");
            request.setRequestHeader("Authorization", `Bearer ${getToken()}`);
          },
          error: (xhr) => {
            // unauthenticated
            if (xhr.status == 401) {
              this.$router.replace({ path: "login" });
            }

            this.$toast.error(xhr.statusText);
          },
        },
        order: [[6, "desc"]],
        columns: [
          {
            data: "DT_RowIndex",
            orderable: false,
            searchable: false,
          },
          {
            data: "reference",
          },
          {
            data: "amount",
            render: (data) => {
              return this.formatMoney(data);
            },
          },
          {
            data: "transaction_type",
            render: function (data) {
              let color = "primary";

              switch (data) {
                case "debit":
                  color = "danger";
                  break;
              }

              return `<span class="badge badge-${color} p-1">${data}</span>`;
            },
          },
          {
            data: null,
            orderable: false,
            render: (data) => {
              if (data.causer_type === "invoice_item") {
                return data.note.replace(
                  "Triggered by Invoice Item",
                  `Triggered by <a href="/medicalrequests/view?invoiceitem=${data.causer_id}" target="_blank">
                  Invoice Item</a>`
                );
              }

              return data.note;
            },
          },
          {
            data: "payment_reference",
            orderable: false,
          },
          {
            data: "created_at",
            searchable: false,
            render: (data) => {
              return this.formatDateTime(data);
            },
          },
        ],
      });
    },
    openExportModal() {
      this.$bvModal.show("export-modal");
      this.fetchVendors();
    },
    exportTransactions() {
      this.exportTransactionsForm
        .post(endpoints.EXPORT_VENDOR_PAYMENT_TRANSACTIONS)
        .then((response) => {
          this.$toast.success(response);
          this.exportTransactionsForm.reset();
          this.$bvModal.hide("export-modal");
        })
        .catch((error) => {
          this.$toast.error(
            error?.response?.data?.message || error?.response?.message
          );
        });
    },
    fetchVendors() {
      http
        .get(
          `${endpoints.VIEW_ALL_VENDORS}?fields[vendors]=id,name&do_not_paginate=1`
        )
        .then((response) => {
          this.vendors = response;
        })
        .catch((error) => {
          this.$toast.error(error.response?.data?.message);
        });
    },
    openVendorWalletForm() {
      this.$bvModal.show("wallet-update-vendor");
    },
    updateVendorWallet() {
      this.walletUpdateForm
        .post(endpoints.UPDATE_VENDOR_WALLET.replace(":id", this.vendor.id))
        .then((response) => {
          this.$toast.success(response);
          this.$bvModal.hide("wallet-update-vendor");
          this.refreshRecords();
          this.walletUpdateForm.reset();
        })
        .catch((error) => {
          this.$toast.error(error.response?.message.message);
        });
    },
  },
  mounted() {
    this.fetchRecords();
    this.getVendorBalances();

    setTimeout(() => {
      if (this.isManager() || this.isAdmin() || this.isMedicalServices()) {
        this.getPaystackBalance();
      }
    }, 1000);

    $("#suspension-modal").on("show.bs.modal", (e) => {
      this.vendor = {
        id: e.relatedTarget.getAttribute("data-vendor-id"),
        email: e.relatedTarget.getAttribute("data-vendor-email"),
        suspended_at: e.relatedTarget.getAttribute("data-vendor-suspended_at"),
      };
    });

    $("#payment-withholding-modal").on("show.bs.modal", (e) => {
      this.vendor = {
        id: e.relatedTarget.getAttribute("data-vendor-id"),
        email: e.relatedTarget.getAttribute("data-vendor-email"),
        payment_withheld_at: e.relatedTarget.getAttribute(
          "data-vendor-payment_withheld_at"
        ),
      };
    });

    $("#trigger-payment-modal").on("show.bs.modal", (e) => {
      this.vendor = {
        id: e.relatedTarget.getAttribute("data-vendor-id"),
        email: e.relatedTarget.getAttribute("data-vendor-email"),
      };
    });

    $("#mock-view-vendor-modal").on("show.bs.modal", (e) => {
      e.preventDefault();

      this.fetchVendor(e.relatedTarget.getAttribute("data-vendor-id"), true);
    });

    $("#mock-wallet-update-vendor-modal").on("show.bs.modal", (e) => {
      e.preventDefault();

      this.vendor = {
        id: e.relatedTarget.getAttribute("data-vendor-id"),
        name: e.relatedTarget.getAttribute("data-vendor-name"),
        email: e.relatedTarget.getAttribute("data-vendor-email"),
        phone_number: e.relatedTarget.getAttribute("data-vendor-phone_number"),
        type: e.relatedTarget.getAttribute("data-vendor-type"),
      };

      this.openVendorWalletForm();
    });

    $("#edit-vendor-modal").on("show.bs.modal", (e) => {
      e.preventDefault();

      this.vendor = {
        id: e.relatedTarget.getAttribute("data-vendor-id"),
        name: e.relatedTarget.getAttribute("data-vendor-name"),
        email: e.relatedTarget.getAttribute("data-vendor-email"),
        phone_number: e.relatedTarget.getAttribute("data-vendor-phone_number"),
        type: e.relatedTarget.getAttribute("data-vendor-type"),
        contact_name: e.relatedTarget.getAttribute("data-vendor-contact_name"),
        contact_phone_number: e.relatedTarget.getAttribute(
          "data-vendor-contact_phone_number"
        ),
      };

      this.editVendor();
    });
  },
};
</script>

<style>
.table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
  text-align: left;
}
</style>
